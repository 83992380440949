<template>
  <div class="modern-color-theme flex justify-center items-center" :style="computedStyle" :class="computedClass" data-component-name="VIcon">
    <Suspense>
      <component :is="iconRenderer" class="w-auto w-full h-full fill-current" />
    </Suspense>
  </div>
</template>
<script lang="ts" setup>
import { computed, defineAsyncComponent, shallowRef, watch } from 'vue'
import VSpinner from './VSpinner.vue'
import type { ColorWithShade, Size } from '../../utils/types';
import type { Icon } from '@icons/index';
import { convertColorToCSSColorVariable } from '@component-utils/colors';

defineOptions({
  name: 'VIcon'
})

const props = defineProps<{
  name: Icon
  dark?: Icon
  size?: Size
  color?: ColorWithShade
  dense?: boolean
}>()

const isDarkMode = computed(() => false) // TODO: Implement dark mode detection

const iconComponentName = computed(() => isDarkMode.value ? (props.dark ?? props.name) : props.name)

const computedSize = computed(() => {
  switch (props.size ?? 'base') {
    case 'xs':
      return '16px'
    case 'sm':
      return '20px'
    case 'base':
      return '24px'
    case 'lg':
      return '28px'
    case 'xl':
      return '32px'
    case '2xl':
      return '36px'
    case '3xl':
      return '40px'
    case '4xl':
      return '44px'
    case '5xl':
      return '48px'
    case '6xl':
      return '52px'
    default:
      return undefined
  }
})

const computedStyle = computed(() => {
  const size = computedSize.value

  return {
    'height': size,
    'min-height': size,
    'max-height': size,
    'width': size,
    'min-width': size,
    'max-width': size,
    'color': color.value
  }
})

const color = computed(() => convertColorToCSSColorVariable(props.color, 400))

const computedClass = computed(() => {
  const classes = []

  if (props.dense) classes.push('-my-1')

  return classes.join(' ')
})

const icons = import.meta.glob(['../../../assets/icons/*.vue', '../../../assets/icons/Solid/*.vue', '../../../assets/icons/Outline/*.vue', '../../../assets/icons/Mini/*.vue', '../../../assets/icons/Micro/*.vue'])

const createIconRender = () => defineAsyncComponent({
  loader: () => {
    if (icons[`../../../assets/icons/${iconComponentName.value}.vue`] == null) {
      return import('../../../assets/icons/NotSet.vue')
    }
    return icons[`../../../assets/icons/${iconComponentName.value}.vue`]() as Promise<{ default: any }>
  },
  loadingComponent: VSpinner,
  suspensible: true
})

const iconRenderer = shallowRef(createIconRender())
watch(iconComponentName, () => {
  iconRenderer.value = createIconRender()
})
</script>
