import { css, getElement } from './dom.js'

const scrollTargets = [
  null,
  typeof document !== 'undefined' ? document : null,
  typeof document !== 'undefined' ? document.body : null,
  typeof document !== 'undefined' ? document.scrollingElement : null,
  typeof document !== 'undefined' ? document.documentElement : null,
]

export function getScrollTarget(
  el?: Element | null,
  targetEl: Element | null = null
) {
  let target = getElement(targetEl)

  if (target === undefined) {
    if (el == null) {
      return window
    }

    target = el.closest('.scroll,.scroll-y,.overflow-auto')!
  }

  return scrollTargets.includes(target) ? window : target
}

export function getScrollHeight(el: Element | Window) {
  return (el === window ? document.body : el).scrollHeight
}

export function getScrollWidth(el: Element | Window) {
  return (el === window ? document.body : el).scrollWidth
}

export function getVerticalScrollPosition(scrollTarget: Element | Window) {
  return scrollTarget === window
    ? window.pageYOffset || window.scrollY || document.body.scrollTop || 0
    : scrollTarget.scrollTop
}

export function getHorizontalScrollPosition(scrollTarget: Element | Window) {
  return scrollTarget === window
    ? window.pageXOffset || window.scrollX || document.body.scrollLeft || 0
    : scrollTarget.scrollLeft
}

export function animVerticalScrollTo(scrollEl: HTMLElement, offset: number, duration = 0, prevTime: number = performance.now(), opts?: {onUpdate?: VoidFunction, onDone?: VoidFunction}) {
  const pos = getVerticalScrollPosition(scrollEl)

  if (duration <= 0) {
    if (pos !== offset) {
      setScroll(scrollEl, offset)
      opts?.onUpdate?.()
    }
    opts?.onDone?.()
    return
  }

  requestAnimationFrame((nowTime) => {
    const frameTime = nowTime - prevTime
    const newPos =
      pos + ((offset - pos) / Math.max(frameTime, duration)) * frameTime
    setScroll(scrollEl, newPos)
    opts?.onUpdate?.()
    if (newPos !== offset) {
      animVerticalScrollTo(scrollEl, offset, duration - frameTime, nowTime, opts)
    } else {
      opts?.onDone?.()
    }
  })
}

export function animHorizontalScrollTo(
  el: Element | Window,
  to,
  duration = 0 /* , prevTime */
) {
  const prevTime = arguments[3] === undefined ? performance.now() : arguments[3]
  const pos = getHorizontalScrollPosition(el)

  if (duration <= 0) {
    if (pos !== to) {
      setHorizontalScroll(el, to)
    }
    return
  }

  requestAnimationFrame((nowTime) => {
    const frameTime = nowTime - prevTime
    const newPos =
      pos + ((to - pos) / Math.max(frameTime, duration)) * frameTime
    setHorizontalScroll(el, newPos)
    if (newPos !== to) {
      animHorizontalScrollTo(el, to, duration - frameTime, nowTime)
    }
  })
}

function setScroll(scrollTarget: Element | Window, offset: number) {
  if (scrollTarget === window) {
    window.scrollTo(
      window.pageXOffset || window.scrollX || document.body.scrollLeft || 0,
      offset
    )
    return
  }
  scrollTarget.scrollTop = offset
}

function setHorizontalScroll(scrollTarget: Element | Window, offset: number) {
  if (scrollTarget === window) {
    window.scrollTo(
      offset,
      window.pageYOffset || window.scrollY || document.body.scrollTop || 0
    )
    return
  }
  scrollTarget.scrollLeft = offset
}

export function setVerticalScrollPosition(
  scrollTarget: Element | Window,
  offset: number,
  duration: number
) {
  if (duration) {
    animVerticalScrollTo(scrollTarget, offset, duration)
    return
  }
  setScroll(scrollTarget, offset)
}

export function setHorizontalScrollPosition(
  scrollTarget: Element | Window,
  offset: number,
  duration: number
) {
  if (duration) {
    animHorizontalScrollTo(scrollTarget, offset, duration)
    return
  }
  setHorizontalScroll(scrollTarget, offset)
}

let size: undefined | number
export function getScrollbarWidth() {
  if (size !== undefined) {
    return size
  }

  const inner = document.createElement('p'),
    outer = document.createElement('div')

  css(inner, {
    width: '100%',
    height: '200px'
  })
  css(outer, {
    position: 'absolute',
    top: '0px',
    left: '0px',
    visibility: 'hidden',
    width: '200px',
    height: '150px',
    overflow: 'hidden'
  })

  outer.appendChild(inner)

  document.body.appendChild(outer)

  const w1 = inner.offsetWidth
  outer.style.overflow = 'scroll'
  let w2 = inner.offsetWidth

  if (w1 === w2) {
    w2 = outer.clientWidth
  }

  outer.remove()
  size = w1 - w2

  return size
}

export function hasScrollbar(el: Element, onY = true) {
  if (!el || el.nodeType !== Node.ELEMENT_NODE) {
    return false
  }

  return onY
    ? el.scrollHeight > el.clientHeight &&
        (el.classList.contains('scroll') ||
          el.classList.contains('overflow-auto') ||
          ['auto', 'scroll'].includes(
            window.getComputedStyle(el)['overflow-y']
          ))
    : el.scrollWidth > el.clientWidth &&
        (el.classList.contains('scroll') ||
          el.classList.contains('overflow-auto') ||
          ['auto', 'scroll'].includes(
            window.getComputedStyle(el)['overflow-x']
          ))
}

export default {
  getScrollTarget,

  getScrollHeight,
  getScrollWidth,

  getVerticalScrollPosition,
  getHorizontalScrollPosition,

  animVerticalScrollTo,
  animHorizontalScrollTo,

  setVerticalScrollPosition,
  setHorizontalScrollPosition,

  getScrollbarWidth,
  hasScrollbar
}
