<template>
  <VBackdrop v-if="loaded" @click="props.closeViaBackdrop && emit('close')">
    <div role="dialog" class="modern-color-theme font-poppins p-5 bg-white rounded-2xl flex flex-col shadow-modal" :class="{ 'relative': props.closeViaButton }" :style="computedLayout" v-bind="$attrs" data-component-name="VDialog" @click.stop>
      <VButton v-if="props.closeViaButton" variant="text" color="neutral" icon :container-props="{ class: 'absolute top-2 right-2' }" @click="emit('close')">
        <VIcon name="Solid/close" />
      </VButton>
      <slot />
      <div v-if="slots.buttons" class="flex justify-end gap-4">
        <slot name="buttons" />
      </div>
    </div>
  </VBackdrop>
</template>
<script setup lang="ts">
import VButton from '@component-library/buttons/VButton.vue';
import VBackdrop from '../layouts/VBackdrop.vue'
import VIcon from '@component-library/labels/VIcon.vue';
import { computed, onMounted, ref, useSlots } from 'vue';
import { useLoader } from '@component-utils/loader';
import { useLocalize } from '@component-utils/localization';
import { useErrorToast } from '@component-utils/toasts';

defineOptions({
  name: 'VDialog',
  inheritAttrs: false
})

const props = withDefaults(
  defineProps<{
    width?: string
    height?: string
    minHeight?: string
    closeViaButton?: boolean
    closeViaBackdrop?: boolean
    load?: () => Promise<void>
  }>(),
  {
    width: '500px',
    height: undefined,
    minHeight: undefined,
    closeViaBackdrop: false,
    closeViaButton: false,
    load: undefined
  }
)

const emit = defineEmits<{
  close: []
}>()

const slots = useSlots()

const computedLayout = computed(() => {
  return {
    minWidth: props.width,
    width: props.width,
    minHeight: props.minHeight ?? props.height,
    height: props.height
  }
})

const loaded = ref(!props.load)

onMounted(async () => {
  if (props.load) {
    const loader = useLoader()
    try {
      loader.start(useLocalize('component-library.layouts')('default_page_loader_message'), { opaque: false })

      await props.load()

      loaded.value = true
    } catch (e) {
      useErrorToast(e)

      emit('close')
    } finally {
      loader.stop()
    }
  }
})
</script>
