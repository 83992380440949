<template>
  <Transition enter-from-class="" enter-active-class="" enter-to-class="">
    <VBackdrop v-if="state.loading" class="flex-col gap-4" :opaque="state.opaque">
      <VIcon size="4xl" color="primary" class="animate-[opacity-25-to-opacity-100_2s_ease-in-out_infinite]" :name="state.icon" />
      <VSpan>{{ state.message }}</VSpan>
    </VBackdrop>
  </Transition>
</template>
<script lang="ts" setup>
import VIcon from '@component-library/labels/VIcon.vue';
import VBackdrop from '@component-library/layouts/VBackdrop.vue';
import VSpan from '@component-library/labels/VSpan.vue';
import { inject, reactive } from 'vue';
import type { Icon } from '@icons/index';

defineOptions({
  name: 'VLoaderDialog'
})

defineEmits<{
  close: []
}>()

const state = inject<{
  message: string | undefined
  icon: Icon
  opaque: boolean
  loading: boolean
}>('V:VLoaderDialog:State', reactive({ message: undefined, icon: 'Outline/avvoka', opaque: true, loading: false }))
</script>
