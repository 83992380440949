import type { Color, ColorShade, ColorWithShade } from "./types"

/**
 * 0 - 255
 * 0 - 255
 * 0 - 255
 */
export type ColorRGB = {
  r: number
  g: number
  b: number
}

/**
 * 0 - 360
 * 0 - 100
 * 0 - 100
 */
export type ColorHSV = {
  h: number
  s: number
  v: number
}

export function isValidHex (hexColor: unknown) {
  return hexColor && typeof hexColor === 'string' && /^[a-f0-9]{6}$/ig.test(hexColor)
}

export function rgbToHex (rgbColor: ColorRGB) {
  const rBytes = (rgbColor.r & 0xff) << 16
  const gBytes = (rgbColor.g & 0xff) << 8
  const bBytes = rgbColor.b & 0xff

  return (rBytes + gBytes + bBytes).toString(16).padStart(6, '0')
}

export function hexToRgb (hexColor: string): ColorRGB {
  const rgb = parseInt(hexColor, 16)

  return {
    r: (rgb >> 16) & 0xff,
    g: (rgb >> 8) & 0xff,
    b: (rgb >> 0) & 0xff
  }
}

export function hsvToRgb (hsvColor: ColorHSV): ColorRGB {
  const h = hsvColor.h / 360
  const s = hsvColor.s / 100
  const v = hsvColor.v / 100

  const i: number = Math.floor(h * 6);
  const f: number = h * 6 - i;
  const p: number = Math.round(v * (1 - s) * 255);
  const q: number = Math.round(v * (1 - f * s) * 255);
  const t: number = Math.round(v * (1 - (1 - f) * s) * 255);

  const w = Math.round(v * 255);

  let r: number = 0
  let g: number = 0
  let b: number = 0

  switch (i % 6) {
    case 0: r = w; g = t; b = p; break
    case 1: r = q; g = w; b = p; break
    case 2: r = p; g = w; b = t; break
    case 3: r = p; g = q; b = w; break
    case 4: r = t; g = p; b = w; break
    case 5: r = w; g = p; b = q; break
  }

  return {
    r,
    g,
    b
  }
}

export function rgbToHsv (rgbColor: ColorRGB): ColorHSV {
  const fR = rgbColor.r / 255
  const fG = rgbColor.g / 255
  const fB = rgbColor.b / 255

  const channelMin = Math.min(fR, fG, fB)
  const channelMax = Math.max(fR, fG, fB)

  const channelDelta = channelMax - channelMin

  let h = 0
  if (channelDelta == 0) {
    h = 0
  } else if (channelMax == fR) {
    h = ((fG - fB) / channelDelta) % 6
  } else if (channelMax == fG) {
    h = (fB - fR) / channelDelta + 2
  } else {
    h = (fR - fG) / channelDelta + 4
  }

  const s = channelMax == 0 ? 0 : channelDelta / channelMax

  return {
    h: (Math.round(h * 60) + 360) % 360,
    s: Math.round(s * 100),
    v: Math.round(channelMax * 100)
  }
}

const createCSSColorVariable = (color: string, shade?: number) => `var(--avv-${color}-${shade ? '' : '-color'}${shade || ''})`

export function convertColorToCSSColorVariable (color: ColorWithShade | undefined, defaultShade?: ColorShade) {
  if (color === 'white' || color === 'black') return color
  else if (color) {
    const [colorName, shade] = color.split('/') as [Color, ColorShade | undefined]

    return createCSSColorVariable(colorName, shade ?? defaultShade)
  }
}
