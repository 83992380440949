<template>
  <p class="modern-color-theme font-poppins" :class="computedClass" :style="{ color }" data-component-name="VParagraph">
    <slot />
  </p>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ColorWithShade } from '../../utils/types';
import { convertColorToCSSColorVariable } from '@component-utils/colors';

defineOptions({
  name: 'VParagraph'
})

const props = withDefaults(
  defineProps<{
    color?: ColorWithShade
    leading?: boolean
    small?: boolean
  }>(),
  {
    color: 'neutral',
    leading: false,
    small: false
  }
)

const computedClass = computed(() => ({
  'text-sm leading-5 font-normal': props.small,
  'text-lg leading-7 font-normal': props.leading,
  'text-base leading-6 font-normal': !props.leading && !props.small
}))

const color = computed(() => convertColorToCSSColorVariable(props.color, 950))
</script>
