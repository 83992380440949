import { ascendantNode, Delta, hasAttributes, isDelete, isInsert, isRetain, type InsertAttributes, type InsertOp, type Op } from '@avvoka/editor'
import { BitArray, clone, Source } from '@avvoka/shared'
import { useSimpleDialog } from '@component-utils/dialogs'
import Axios from 'axios'
import { getActivePinia } from 'pinia'
import { useTemplateVersionStore } from '~/stores/generic/templateVersion.store'
import { animVerticalScrollTo } from './_abstract/utils/scroll'
import { getCurrentDocumentId, useDocumentStore } from '~/stores/generic/document.store'

export default class Utils {
  public static get axios() {
    Axios.defaults.headers.common['X-CSRF-Token'] = Utils.CSRFToken
    Axios.interceptors.response.use(
      function (response) {
        window.localStorage.avvokaLastRequest = Date.now()
        return response
      },
      function (error) {
        window.localStorage.avvokaLastRequest = Date.now()
        return Promise.reject(error)
      }
    )
    return Axios
  }

  // User::VALID_EMAIL_REGEX
  public static isEmailValid(email: string | null | undefined) {
    return email && /^[a-zA-Z0-9.!$%‘*+/=?^_`{|}~-]+@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email)
  }

  public static isURLValid(url: string | null | undefined) {
    return (
      url &&
      /([a-zA-Z][\-+.a-zA-Z\d]*):(?:((?:[\-_.!~*'()a-zA-Z\d;?:@&=+$,]|%[a-fA-F\d]{2})(?:[\-_.!~*'()a-zA-Z\d;\/?:@&=+$,\[\]]|%[a-fA-F\d]{2})*)|(?:(?:\/\/(?:(?:(?:((?:[\-_.!~*'()a-zA-Z\d;:&=+$,]|%[a-fA-F\d]{2})*)@)?(?:((?:(?:[a-zA-Z0-9\-.]|%\h\h)+|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|\[(?:(?:[a-fA-F\d]{1,4}:)*(?:[a-fA-F\d]{1,4}|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3})|(?:(?:[a-fA-F\d]{1,4}:)*[a-fA-F\d]{1,4})?::(?:(?:[a-fA-F\d]{1,4}:)*(?:[a-fA-F\d]{1,4}|\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))?)\]))(?::(\d*))?))?|((?:[\-_.!~*'()a-zA-Z\d$,;:@&=+]|%[a-fA-F\d]{2})+))|(?!\/\/))(\/(?:[\-_.!~*'()a-zA-Z\d:@&=+$,]|%[a-fA-F\d]{2})*(?:;(?:[\-_.!~*'()a-zA-Z\d:@&=+$,]|%[a-fA-F\d]{2})*)*(?:\/(?:[\-_.!~*'()a-zA-Z\d:@&=+$,]|%[a-fA-F\d]{2})*(?:;(?:[\-_.!~*'()a-zA-Z\d:@&=+$,]|%[a-fA-F\d]{2})*)*)*)?)(?:\?((?:[\-_.!~*'()a-zA-Z\d;\/?:@&=+$,\[\]]|%[a-fA-F\d]{2})*))?)(?:\#((?:[\-_.!~*'()a-zA-Z\d;\/?:@&=+$,\[\]]|%[a-fA-F\d]{2})*))?/i.test(
        url
      )
    )
  }

  public static DIVIDER_TOKEN = '#='

  public static get CSRFToken() {
    return document.getElementsByName('csrf-token')?.[0]?.getAttribute('content') as string
  }

  public static removeNonBreakingSpaces(str: string) {
    return str.replace(/\u200B/g, '').replace(/&#8203;/g, '')
  }

  public static fileIcon(fileType: string) {
    switch (fileType) {
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      case 'application/msword':
      case 'text/plain':
        return 'description'
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      case 'application/vnd.ms-excel.template.macroEnabled.12':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.template':
      case 'application/vnd.ms-excel.sheet.macroEnabled.12':
        return 'backup_table'
      case 'application/pdf':
        return 'picture_as_pdf'
      case 'application/zip':
        return 'folder_zip'
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'slide_library'
      case 'application/vnd.ms-outlook':
      case 'message/rfc822':
        return 'mail'
      default:
        if (fileType?.startsWith('image')) {
          return 'image'
        }
        return 'draft'
    }
  }

  public static copyText(text: string) {
    const element = document.createElement('textarea')
    element.value = text
    document.body.appendChild(element)
    element.select()
    document.execCommand('copy')
    document.body.removeChild(element)
    window.getSelection()?.removeAllRanges()
  }

  /**
   * Deep copy an object
   * @deprecated Use clone from '@avvoka/shared' instead
   * @param value
   */
  public static deepCopy<T>(value: T): T {
    return clone(value)
  }

  public static highlightNode(node: Node, flashDelay: number) {
    ;(node as HTMLElement).style.setProperty('--flashing-interval', flashDelay + 'ms')
    setTimeout(() => {
      ;(node as HTMLElement).classList.toggle(`fade-out-highlight`, true)
      setTimeout(() => (node as HTMLElement).classList.toggle(`fade-out-highlight`, false), flashDelay)
    })
  }

  public static scrollNodeIntoView(
    node: HTMLElement,
    scroll: HTMLElement = ascendantNode(node, (node) => node.nodeName === 'AVV-CONTAINER' || node === window) as HTMLElement,
    duration = 500,
    opts?: { onUpdate?: VoidFunction; onDone?: VoidFunction }
  ) {
    animVerticalScrollTo(scroll, node.getBoundingClientRect().top + scroll.scrollTop - scroll.getBoundingClientRect().top, duration, undefined, opts)
  }

  public static notANumber(arg: any): boolean {
    return typeof arg !== 'number' || isNaN(arg)
  }

  public static stringifyValues(object: any): any {
    if (object === null || object === undefined) {
      return object
    } else if (object instanceof Array) {
      return object.map((value: any) => Utils.stringifyValues(value))
    } else {
      return String(object)
    }
  }
}

export const initializeAfter = (body: VoidFunction, interval: number, stopCondition: () => boolean) => {
  const intervalId = setInterval(() => {
    if (stopCondition()) {
      clearInterval(intervalId)
      body()
    }
  }, interval)
}
if (typeof window !== 'undefined') {
  window.initializeAfter = initializeAfter
}

/**
 * Calculates the character and word count from an array of operations (`ops`).
 * It processes each operation to extract text content, handling different types of inserts such as strings, formulas, and tabs.
 * The function returns an object containing the total number of characters and words.
 *
 * @param {Op[]} ops - An array of operations, where each operation can contain different types of inserts (e.g., strings, formulas, tabs).
 * @returns {{ words: number, chars: number }} An object with two properties:
 * - `chars`: The total number of characters in the processed content.
 * - `words`: The total number of words in the processed content.
 */
export const getCharAndWordCount = (ops: Op[]): { words: number; chars: number } => {
  const flatContent = ops
    .map((op) => {
      if (isInsert(op) && (!hasAttributes(op) || (hasAttributes(op) && !op.attributes.delete))) {
        if (typeof op.insert === 'string') {
          return op.insert
        } else if (op.insert.formula) {
          // mappings to make char count align with that of Word
          const charMappings = {
            pm: '±',
            mp: '∓',
            pi: 'π',
            dotsc: '…',
            infty: '∞',
            alpha: 'α',
            beta: 'β',
            gamma: 'γ',
            lt: '&lt;',
            gt: '&gt;',
            prod: '∏',
            sum: '∑',
            sqrt: '',
            '{': '',
            '}': '',
            '\\^': ''
          }

          let cleanedFormula = op.insert.formula['data-value']
          for (const [mapping, replacement] of Object.entries(charMappings)) {
            const regex = new RegExp(mapping, 'g')
            cleanedFormula = String(cleanedFormula).replace(regex, replacement)
          }

          return String(cleanedFormula).replace(/\\/g, '').replace(/ /g, '')
        } else if (op.insert.avvTab) {
          // count each tab as 1 character
          return ' '
        } else {
          return ''
        }
      }
      return null
    })
    .join('')

  const chars = flatContent.replace(/\n/g, '').length
  const words = flatContent.length && flatContent.trim() ? flatContent.trim().split(/\s+/).length : 0

  return { chars, words }
}

/**
 * Handles character autoreplacement.
 * 
 * @returns A promise that resolves to void.
 */
export const handleCharacterAutoreplacement = async (): Promise<void> => {
  const store = useDocumentStore(getActivePinia())
  await store.hydrateById(getCurrentDocumentId(), ['validations'])

  const { enabled, rules } = store.validations.character_autoreplacement

  const madeReplacements: Map<string, string> = new Map<string, string>()

  const isOpValidForReplacement = (op: Op) => {
    const isStringInsert = isInsert(op) && typeof op.insert === 'string'
    const isNotDeleteInsert = !(hasAttributes(op) && op.attributes.delete)
    return isStringInsert && isNotDeleteInsert
  }

  const applyReplacements = (op: InsertOp): string => {
    const originalText = op.insert as string
    let modifiedText = originalText

    rules.forEach((rule) => {
      const searchValue = rule.from
      const replaceValue = rule.to
      const newText = modifiedText.replaceAll(searchValue, replaceValue)

      if (newText !== modifiedText) {
        if (!madeReplacements.has(searchValue)) {
          madeReplacements.set(searchValue, replaceValue)
        }
      }

      modifiedText = newText
    })

    return modifiedText
  }

  return new Promise<void>((resolve) => {
    if (!enabled || !rules?.length) {
      resolve()
      return
    }

    const editor = EditorFactory.get('draft').get()
    const ops = editor.getDelta().ops

    if (!ops.length) {
      resolve()
      return
    }

    const updateDelta = new Delta()

    ops.forEach((op) => {
      if (isOpValidForReplacement(op)) {
        const finalReplacement = applyReplacements(op as InsertOp)
        updateDelta.insert(finalReplacement, (op as InsertOp).attributes as InsertAttributes)
      } else if (isRetain(op)) {
        updateDelta.retain(op.retain, op.attributes)
      } else if (isDelete(op)) {
        updateDelta.delete(op.delete)
      } else {
        updateDelta.insert(op.insert, op.attributes)
      }
    })

    editor.scroll.clear()
    editor.update(updateDelta, new BitArray().set(Source.TRACKING_CHANGES))

    if (!madeReplacements.size) {
      resolve()
      return
    }

    const replacementsString = Array.from(madeReplacements.entries())
      .map(([from, to]) => `[${from} -> ${to}]`)
      .join(', ')

    useSimpleDialog({
      message: localizeText('template.info_messages.replaced_characters', { replacements: replacementsString }),
      buttons: ['ok'],
    }, {
      callback: () => resolve()
    })
  })
}
