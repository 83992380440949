import { isRef, type Ref } from 'vue'

export function offset(el: Element) {
  if (el === window) {
    return { top: 0, left: 0 }
  }
  const { top, left } = el.getBoundingClientRect()
  return { top, left }
}

export function style(el: Element, property) {
  return window.getComputedStyle(el).getPropertyValue(property)
}

export function height(el: Element) {
  return el === window ? window.innerHeight : el.getBoundingClientRect().height
}

export function width(el: Element) {
  return el === window ? window.innerWidth : el.getBoundingClientRect().width
}

export function css(element: Element, css: Record<string, string>) {
  const style = element.style

  for (const prop in css) {
    style[prop] = css[prop]
  }
}

export function cssBatch(elements: Element[], style: Record<string, string>) {
  elements.forEach((el) => css(el, style))
}

export function ready(fn?: VoidFunction) {
  if (typeof fn !== 'function') {
    return
  }

  if (document.readyState !== 'loading') {
    return fn()
  }

  document.addEventListener('DOMContentLoaded', fn, false)
}

// internal
export function getElement(
  el?: Element | null | string | Ref<Element>
): Element | undefined {
  if (el == null) {
    return undefined
  }

  if (typeof el === 'string') {
    try {
      return document.querySelector(el) || undefined
    } catch (err) {
      return undefined
    }
  }

  const target = isRef(el) ? el.value : el

  if (target) {
    return target.$el || target
  }
}

// internal
export function childHasFocus(
  el?: Element | null,
  focusedEl: Element | null = null
) {
  if (el == null || el.contains(focusedEl) === true) {
    return true
  }

  for (
    let next = el.nextElementSibling;
    next !== null;
    next = next.nextElementSibling
  ) {
    if (next.contains(focusedEl)) {
      return true
    }
  }

  return false
}

export function getElementByIdStrict<T extends HTMLElement = HTMLElement>(id: string, document: Document = window.document): T | never {
  const element = document.getElementById(id)
  if (element == null) {
    throw new Error(`Cannot find element with id '${id}'`)
  } else {
    return element as T
  }
}

export default {
  offset,
  style,
  height,
  width,
  css,
  cssBatch,
  ready
}
