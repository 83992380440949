<template>
  <component :is="computedName" class="modern-color-theme font-poppins" :class="computedClass" :style="{ color }" data-component-name="VHeading">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ColorWithShade } from '../../utils/types';
import { convertColorToCSSColorVariable } from '@component-utils/colors';

defineOptions({
  name: 'VHeading'
})

const HEADING_LEVELS = [1, 2, 3, 4, 5, 6] as const

const props = withDefaults(
  defineProps<{
    level: typeof HEADING_LEVELS[number] | `${typeof HEADING_LEVELS[number]}`
    medium?: boolean
    semibold?: boolean
    color?: ColorWithShade
  }>(),
  {
    color: 'neutral',
    medium: false,
    semibold: false
  }
)

const computedLevelClass = computed(() => ({
  1: 'text-5xl leading-none',
  2: 'text-4xl leading-10',
  3: 'text-3xl leading-9',
  4: 'text-2xl leading-8',
  5: 'text-xl leading-7',
  6: 'text-lg leading-7'
}[props.level]))

const computedClass = computed(() => ({
  [computedLevelClass.value]: true,
  'font-bold': !props.medium && !props.semibold,
  'font-medium': props.medium,
  'font-semibold': props.semibold
}))

const computedName = computed(() => `h${props.level}`)

const color = computed(() => convertColorToCSSColorVariable(props.color, 950))
</script>
