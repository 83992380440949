<template>
  <span class="modern-color-theme font-poppins" :class="computedClass" :style="{ color }" data-component-name="VSpan">
    <slot />
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { ColorWithShade, Size } from '../../utils/types';
import { convertColorToCSSColorVariable } from '@component-utils/colors';

defineOptions({
  name: 'VSpan'
})

const props = withDefaults(
  defineProps<{
    size?: Size
    color?: ColorWithShade
    bold?: boolean
    italic?: boolean
  }>(),
  {
    size: 'sm',
    color: 'neutral',
    bold: false,
    italic: false
  }
)

const computedClass = computed(() => {
  const styles = []

  if (props.size) styles.push(`text-${props.size}`)
  if (props.bold) styles.push('font-bold')
  if (props.italic) styles.push('italic')

  return styles.join(' ')
})

const color = computed(() => convertColorToCSSColorVariable(props.color, 500))
</script>
